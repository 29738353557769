
import { defineComponent, ref, onMounted, computed } from "vue";
import { StepperComponent } from "@/assets/ts/components/_StepperComponent";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import GdprDocViewer from "@/views/GdprDocViewer.vue";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
    GdprDocViewer,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const toggleVisibility = ref<boolean>(true);

    const _stepperObj = ref<StepperComponent | null>(null);
    const signUpStepper = ref<HTMLElement | null>(null);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const currentStepIndex = ref(0);
    const registration = Yup.object().shape({
      first_name: Yup.string()
        .required("Le prénom est requis")
        .max(30, "Le ${label} ne doit pas dépasser ${max} caractères")
        .label("Name"),
      last_name: Yup.string()
        .required("Le nom est requis")
        .max(150, "Le ${label} ne doit pas dépasser ${max} caractères")
        .label("Surname"),
      email: Yup.string()
        .min(4)
        .required("L'email est requis")
        .email("Le format de l'email n'est pas valide")
        .max(254, "Le ${label} ne doit pas dépasser ${max} caractères"),
      password: Yup.string()
        .required("Le mot de passe est requis")
        .min(
          10,
          "Ce mot de passe est trop court. Il doit contenir au moins 10 caractères."
        )
        .matches(/[a-z]/, "Ce mot de passe doit contenir au moins 1 minuscule.")
        .matches(/[A-Z]/, "Ce mot de passe doit contenir au moins 1 majuscule.")
        .matches(/\d/, "Ce mot de passe doit contenir au moins 1 chiffre.")
        .matches(
          /[~!@#$%^&*()_+{}":;'\\[\],.\\/=+€°§¿•±¥Ø-]/,
          "Ce mot de passe doit contenir au moins 1 caractère spécial."
        ),
      password_confirm: Yup.string()
        .required("La confirmation du mot de passe est requise")
        .oneOf(
          [Yup.ref("password"), null],
          "Les mots de passe doivent correspondre"
        ),
      professional_farmer: Yup.string()
        .required("Vous devez choisir un statut")
        .oneOf(["1", "0"]),
    });
    let cguAccepted = ref<boolean>(false);
    let charterAccepted = ref<boolean>(false);

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }
      return _stepperObj.value.totatStepsNumber;
    });

    const stepValid = computed(() => {
      if (_stepperObj.value) {
        if (currentStepIndex.value === 0) {
          return true;
        } else if (currentStepIndex.value === 1) {
          return cguAccepted.value;
        } else if (currentStepIndex.value === 2) {
          return charterAccepted.value;
        }
      }
      return false;
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      currentStepIndex.value--;
      _stepperObj.value.goPrev();
    };

    const nextStep = () => {
      if (!_stepperObj.value) {
        return;
      }
      window.scrollTo(0, 0);
      currentStepIndex.value++;
      _stepperObj.value.goNext();
    };
    let debounceTimeout: number | null = null;
    const onSubmitRegister = async (values) => {
      if (debounceTimeout !== null) {
        return;
      }
      debounceTimeout = setTimeout(() => {
        debounceTimeout = null;
      }, 2000);
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        submitButton.value?.setAttribute("data-kt-indicator", "on");
      }
      // Send login request
      values.email = values.email.toLowerCase();
      values.cgu = values.cgu === "1";
      values.professional_farmer = values.professional_farmer === "1";
      values.username = values.email;
      if (route.query && route.query.projectId) {
        values.project_id = route.query.projectId;
      }

      store
        .dispatch(Actions.REGISTER, values)
        .then((response) => {
          Swal.fire({
            title: "Ce n'est pas fini !",
            html:
              "Votre inscription a bien été enregistrée ! Un email de confirmation vous a été envoyé à l'adresse <b>" +
              values.email +
              "</b>. <br><br>Veuillez consulter ce mail et cliquer sur le lien de validation pour rendre votre compte actif.",
            text:
              "Votre inscription a bien été enregistrée ! Un email de confirmation vous a été envoyé à l'adresse " +
              values.email +
              ".\n\r Veuillez consulter ce mail et cliquer sur le lien de validation pour rendre votre compte actif.",
            icon: "success",
            confirmButtonText: "J'ai compris !",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
              icon: "primary",
            },
          }).then(() => {
            debounceTimeout = null;
            router.push({ name: "sign-in" });
          });
        })
        .catch(() => {
          debounceTimeout = null;
          router.push({ name: "sign-up" });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        signUpStepper.value as HTMLElement
      );
    });

    return {
      signUpStepper,
      submitButton,
      currentStepIndex,
      registration,
      cguAccepted,
      charterAccepted,
      totalSteps,
      stepValid,
      previousStep,
      nextStep,
      onSubmitRegister,
      toggleVisibility,
    };
  },
});
